.header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  background: transparent;
  color: white;
  align-items: center;
  z-index: 100;
  width: 100%;
  transition: 0.5s ease;
  // background-color: rgba(0, 0, 0, 0.454);
  & img {
    width: 13rem;
    padding: 0 0.6rem;
  }
}

// Nav menu
.nav__menu {
  margin-right: 3rem;
  display: flex;
  align-items: baseline;
  gap: 3rem;
  & .nav__menu-item {
    font-size: 1.2rem;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.854);

    & .nav__menu-item__link {
      transition: 0.3s ease;
      padding: 0.4rem;
      @media (hover: hover) {
        &:hover {
          color: $gold;
        }
      }
    }
    & .nav__menu-item__dropdown-button {
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.854);

      font-size: 1.2rem;

      padding: 0.4rem;

      display: flex;
      align-items: center;
      gap: 0.4rem;
    }
    // if hovering a nav__menu-item that includes a dropdown, display it
    @media (hover: hover) {
      &:hover .nav__dropdown {
        // transition-delay: 0.1s;
        @include min-1000 {
          height: 190px;
          opacity: 1;
        }
      }
      &:hover .dropdown-button__arrow {
        @include min-1000 {
          color: $gold;
        }
      }
    }
    & .dropdown-button__arrow {
      transition: 0.3s ease;
    }
  }

  // Practice areas dropdown
  & .nav__dropdown {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: height 0.3s ease, opacity 0.5s ease;
    flex-direction: column;
    position: absolute;
    gap: 1rem;
    font-size: 1.2rem;

    & li {
      margin-top: 1rem;
    }
    // background-color: rgba(0, 0, 0, 0.56);

    & .nav__dropdown-link {
      width: 100%;
      padding: 0.4rem;
      padding-right: 1rem;
      transition: 0.3s ease;
      @media (hover: hover) {
        &:hover {
          color: $gold;
        }
      }
    }
  }
}

.nav {
  // Switch to mobile Nav
  @include max-1000 {
    position: fixed;
    top: 0;
    right: 0;
    padding: 2rem;
    height: 100vh;
    background-color: white;
    color: black;
    margin-right: 0;
    display: flex;
    align-items: center;
    transform: translateX(100%);
    transition: 0.3s ease;
    &.nav--open {
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.387);
      transform: translateX(0);
    }

    & .nav__menu {
      flex-direction: column;
      margin-right: 0;
      gap: 2rem;
    }
    & .nav__menu-item {
      width: 100%;
      text-shadow: none;
      & .nav__menu-item__link {
        width: 100%;
        display: flex;
      }
      & .nav__menu-item__dropdown-button {
        text-shadow: none;
      }
    }
    & .nav__dropdown {
      position: static;
      display: flex;
      margin-left: 1.4rem;
      & .nav__dropdown-link {
        padding: 0;
      }
      &.mobile-dropdown__open {
        height: 260px;
        opacity: 1;
      }
    }
  }
}
