.practice-area {
  max-width: 70rem;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 1rem;

  @include max-900 {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 1rem;
  }
  & section {
    padding: 1rem;
    padding-right: 4rem;
    @include max-900 {
      padding-right: 1rem;
    }
  }
  & .practice-area__paragraph {
    line-height: 1.4;
    margin-bottom: 1.6rem;
    color: #666;
    font-weight: normal;
  }

  // Sidebar
  & aside {
    padding: 1rem;
  }
  & .services-container {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.373);

    padding: 1.4rem;

    border-radius: 3px;
    & ul li {
      margin-top: 0.6rem;
      & a {
        display: flex;
        align-items: center;
        transition: 0.2s ease;
        @media (hover: hover) {
          &:hover {
            color: $gold;
          }
        }
      }
    }
  }
  & .form-container {
    margin-top: 2rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.373);

    padding: 1.4rem;
    border-radius: 3px;
  }

  & form {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    & textarea {
      margin-top: 0.2rem;
      margin-bottom: 1rem;
      resize: none;
      padding: 0.36rem;
      width: 100%;
      height: 6rem;
      font-family: "Roboto", sans-serif;
    }
    & textarea,
    & input {
      margin-bottom: 0.6rem;
      padding: 0.45rem;
      font-size: 1rem;
      color: $text--dark;
      border: 1px solid $text;
      border-radius: 2px;
      &:focus {
        outline: 3px solid $gold;
        border: 1px solid transparent;
      }
    }
    & button {
      margin-top: 0.4rem;
      padding: 1rem 2rem;
      background-color: $gold;
      border: 1px solid $gold;
      color: white;
      transition: 0.3s;
      text-transform: uppercase;
      @include max-700 {
        width: 100%;
      }
      &:hover {
        background-color: transparent;
        color: $gold;
      }
    }
  }
}
