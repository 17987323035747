.banner {
  width: 100%;
  height: 18rem;
  // padding-top: 100px;
  background-image: url("./images/banners/contact-banner.webp");
  background-size: cover;
  background-position: center;
}
.banner-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.686);
  display: flex;
  justify-content: center;
  align-items: center;
  & h1 {
    // margin-top: 85px;
    align-self: flex-end;
    margin-bottom: 5rem;
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    border: 3px solid white;
    padding: 1rem;
    letter-spacing: 3px;
  }
}
