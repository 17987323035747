#about {
  max-width: 70rem;
  margin: 0 auto;
  margin-bottom: 4rem;
  padding: 2rem;
  @include max-700 {
    padding: 1rem;
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  @include max-900 {
    display: flex;
    flex-direction: column;
  }
  & .about-text {
    padding: 2rem;
    @include max-440 {
      padding: 1rem;
    }
    & > p {
      margin-bottom: 2rem;
      line-height: 1.3;
      color: #666;
    }
  }
  & .about-image {
    margin: 2rem;
    padding-top: 115%;
    background-image: url(https://i.pinimg.com/736x/5d/37/9c/5d379c60f389adb6af6507754685d267.jpg);
    background-size: cover;
    background-position: 50% 50%;
    @include max-900 {
      width: 90%;
    }
  }
  & .about-quote {
    color: $gold;
    font-size: 1.1rem;
    // font-weight: 500;
    font-style: italic;
    margin-bottom: 1rem;
  }
  & .about-signature {
    width: 60%;
  }
}
