#services {
  max-width: 70rem;
  margin: 0 auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding: 2rem;
  @include max-700 {
    padding: 1rem;
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding-top: 0;
  @include max-900 {
    grid-template-columns: 1fr;
  }

  & .services-text {
    @include max-900 {
      grid-row: 1;
    }
    padding: 1rem;
    & > p {
      color: #666;
      line-height: 1.3;
      margin-bottom: 1rem;
    }
  }
  & .services-icons {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    // align-items: center;
    justify-items: center;

    @include max-440 {
      padding: 0;
      gap: 0;
    }
    & .service {
      padding: 2rem 1rem 2.6rem 1rem;
      text-align: center;
      color: #666;
      width: 100%;
      height: 100%;
      border: 2px solid transparent;
      transition: 0.3s;
      transform: translateY(0);

      &:hover {
        transform: translateY(-1rem);

        border: 2px solid $gold;
      }
      & h4 {
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 0.8rem;
      }
      & p {
        font-size: 0.9rem;
      }
      & .icon {
        font-size: 2.2rem;
        color: $gold;
        margin-bottom: 1rem;
      }
    }
  }
}
