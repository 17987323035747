#team {
  max-width: 70rem;
  margin: 0 auto;
  & .team {
    display: grid;
    align-items: center;
    margin-bottom: 0;

    & .team-socials {
      display: flex;
      color: $gold;
      cursor: pointer;
      font-size: 1.3rem;
      margin-top: 0.6rem;
      gap: 1.4rem;
      & li {
        transition: 0.2s ease;
      }
      & li:hover {
        transform: translateY(-2px);
      }
    }
    & .team-text {
      padding: 2rem;
      @include max-440 {
        padding: 1rem;
      }
      & > p {
        margin-bottom: 2rem;
        line-height: 1.3;
        color: #666;
      }
    }
    & .team-image {
      margin: 2rem;
      padding-top: 115%;
      background-size: cover;
      background-position: 50% 50%;
      @include max-900 {
        width: 90%;
      }
    }
    & .team-quote {
      color: $gold;
      font-size: 1.1rem;
      // font-weight: 500;
      font-style: italic;
      margin-bottom: 1rem;
    }
    & .team-signature {
      width: 60%;
    }
  }
  & .team-one {
    grid-template-columns: 1.2fr 1fr;
    padding-bottom: 0;
    @include max-900 {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  & .team-two {
    grid-template-columns: 1fr 1.2fr;
    @include max-900 {
      display: flex;
      flex-direction: column;
      padding-top: 0;
    }
  }
  & .team-one .team-image {
    background-image: url(./images/team-3.jpg);
  }
  & .team-two .team-image {
    background-image: url(./images/team-4.jpg);
  }
}
