/* MOBILE NAVIGATION */
.nav__mobile-button {
  height: 100%;
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-left: 0.2rem;
  margin-right: 1rem;
  display: none;
  @include max-1000 {
    display: flex;
  }
}

.nav__mobile-button__hamburger {
  width: 2.2rem;
  height: 1.7rem;
  position: relative;
  margin: 1rem;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}

.nav__mobile-button__hamburger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.nav__mobile-button__hamburger.nav__mobile-button__hamburger-open span {
  background: black;
}

.nav__mobile-button__hamburger span:nth-child(1) {
  top: 0;
}

.nav__mobile-button__hamburger span:nth-child(2) {
  top: 10px;
}

.nav__mobile-button__hamburger span:nth-child(3) {
  top: 20px;
}

.nav__mobile-button__hamburger.nav__mobile-button__hamburger-open
  span:nth-child(1) {
  height: 2px;
  top: 10px;
  transform: rotate(135deg);
}

.nav__mobile-button__hamburger.nav__mobile-button__hamburger-open
  span:nth-child(2) {
  opacity: 0;
  left: -110%;
}

.nav__mobile-button__hamburger.nav__mobile-button__hamburger-open
  span:nth-child(3) {
  height: 2px;
  top: 10px;
  transform: rotate(-135deg);
}
