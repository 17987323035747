.awards {
  width: 100%;
  max-width: 100%;
  padding: 1rem;
  & ul {
    display: flex;
    justify-content: space-around;
    @include max-1000 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      row-gap: 2rem;
    }
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    // justify-items: center;
    filter: grayscale(1);
  }
  & li {
    @include max-1200 {
      &:nth-child(2) {
        display: none;
      }
    }
    @include max-1000 {
      &:nth-child(4) {
        grid-row: 2;
        grid-column: 1 / 3;
      }
    }
  }
  & img {
    height: 100px;
    @include max-440 {
      height: 85px;
    }
  }
}
