.swiper-button-prev,
.swiper-button-next {
  color: $gold;
  @include max-700 {
    display: none;
  }
}
.swiper-pagination-bullet {
  width: 0.8rem;
  height: 0.8rem;
}
.swiper-pagination-bullet-active {
  background-color: $gold;
}

#testimonials {
  max-width: 70rem;
  margin: 0 auto;
  margin-bottom: 4rem;
  margin-top: 4rem;
  padding: 2rem;
  font-family: "Source Sans Pro", sans-serif;
  @include max-700 {
    padding: 1rem;
  }
  width: 100%;
  & .stars {
    width: 10rem;
    align-self: center;
  }
  padding: 4rem;
  @include max-700 {
    padding: 2rem;
  }
  & .reviews {
    display: flex;
    justify-content: space-between;
  }

  @include max-700 {
    .review:nth-child(2),
    .review:nth-child(3) {
      display: none;
    }
  }
  & .review {
    @include max-700 {
      width: 100%;
      padding-top: 0;
      padding-bottom: 3rem;
    }
    cursor: grab;
    width: 50%;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 6rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    & .quote-icon {
      color: $text;
      font-size: 8rem;
      position: absolute;
      opacity: 0.1;
      left: -5rem;
      top: 3rem;
      transform: scaleX(-1);
      @include max-700 {
        top: 1rem;
        font-size: 12rem;
        left: -2rem;
      }
    }
    & .review-text {
      font-size: 1.1rem;
      margin-top: 1rem;
      line-height: 1.3;
      color: $text--dark;
    }
    & .review-author {
      font-weight: 500;
      font-style: italic;
    }
  }
}
