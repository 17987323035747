#cta {
  width: 100%;
  max-width: 100%;
  background-image: url("./images/cta.jpg");
  background-size: cover;
  background-position: center;
  // position: relative;
  padding: 0;
  & .cta-overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.705);
    // background-color: #17335ad2;
  }
  & .cta-container {
    @include max-440 {
      padding: 2rem 1rem;
    }
    color: white;
    padding: 2rem;
    max-width: 60rem;
    text-align: center;
    margin: 0 auto;
    & h3 {
      color: white;
      margin-bottom: 1rem;
      font-size: 2rem;
      // color: $gold--light;
      font-weight: 700;
      text-shadow: 1px 1px 4px black;
    }
    & p {
      line-height: 1.3;
      margin-bottom: 0.8rem;
      text-shadow: 1px 1px 4px $blue;
      text-shadow: 1px 1px 4px black;
    }
    & a {
      display: inline-block;
      margin-top: 0.6rem;
      padding: 1rem 2rem;
      background-color: $gold;
      border: 1px solid $gold;
      transition: 0.3s;
      text-transform: uppercase;
      font-weight: 600;
      &:hover {
        background: transparent;
        color: $gold;
      }
    }
  }
}
